import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../Constants/API_Backend_Root";
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import UtilitiesSampleRequest from "../UtilitiesSampleRequest"
import UpgradeToTeam from "../../FileDnD/UpgradeToTeam"
import {getFullAPIUrl} from "../getFullAPIUrl";
import UtilitiesSampleResponse from "../UtilitiesSampleResponse"
import UtilitiesCodeSnippet from "../UtilitiesCodeSnippet";
import AceEditor from "react-ace";
import 'brace/mode/python';
import CustomAccordion from "../../SharedComponents/CustomAccordion"
import AnalyticsBody from "../../Analytics/AnalyticsBody";
import APICreateBox from "./APICreateBox";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {Button} from "semantic-ui-react";
import {toggleToast} from "../../Actions/ToggleToast";

class APIAccordion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            saveOldAPI: false
        }
    }

    saveOldAPI = () => {
        this.setState({ saveOldAPI: true });

        const isError = () => {
            this.setState({ saveOldAPI: false },
                () =>
                    this.props.toggleToast({show: true, message: "There was an error saving your API! Please try again", type: "error"}))
        }

        fetch(API_Backend_Root + "api/save-old-python-api/", {
            method: "POST",
            body: JSON.stringify(this.props.api)
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    saveOldAPI: false
                }, () => {
                    this.props.toggleToast({show: true, message: "Code saved!", type: "success"})
                })
            }
        }).catch(err => isError())
    }

    render() {
        const url = API_Backend_Root + "custom-api/" + this.props.api.hash + "/"

        return (
            <div>
                <CustomAccordion
                    body={<div>
                        <div style={{ marginBottom: "12px"}}>
                            <div className="row">
                                <div className="col-sm-9">
                                    <RequestURLBox url={url} />
                                </div>
                                <div className="col-sm-3">
                                    <div>
                                        <Button content="Save Code"
                                                icon="save"
                                                style={gunaldiStyles.btnBlue}
                                                loading={this.state.saveOldAPI}
                                                onClick={this.saveOldAPI}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <APICreateBox idx={this.props.idx} api={this.props.api} updateAPI={this.props.updateAPI}/>
                            </div>
                        </div>
                    </div>}
                    header={"API " + this.props.api.hash}
                    accordionStyle={{
                        borderRadius: '4px',
                        border: "1px solid #F0F0F0",
                        backgroundColor: 'white'
                        // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"
                    }}
                    headerDivStyle={{
                        borderRadius: '4px',
                        backgroundColor: 'white',
                        fontSize: '14px',
                        fontWeight: '700',
                        fontFamily: "Lato",
                        padding: "16px"
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(APIAccordion)