import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Icon } from 'semantic-ui-react'
import {toggleToast} from "../Actions/ToggleToast";
import {getConnectedDomainLink} from "../Constants/getConnectedDomainLink";
import {store} from "../index";
import API_Backend_Root from "../Constants/API_Backend_Root";

class RequestURLBox extends Component {
    constructor(props) {
        super(props);
    }

    copyURL = (e) => {
        // e.target.focus();
        navigator.clipboard.writeText(this.props.url)
        document.execCommand("copy")
        window.getSelection().removeAllRanges();

        this.props.toggleToast({show: true, message: "URL Copied!", type: "success"})
    }



    render() {
        let color = "orange";
        let requestType = "POST"

        let allClassName = "";

        let noBorder = false
        if ('noBorder' in this.props){
            noBorder = this.props.noBorder
        }

        if (!noBorder){
            allClassName = "codeFileURL"
        }


        return (
            <div className={allClassName} id="fileURL">
                <div style={{marginBottom: "6px"}}>
                    <span>
                        <span style={{verticalAlign: "middle", fontSize: "16px", color: "#161E16", fontWeight: "700", fontFamily: "Lato"}}><i className='bx bx-window-alt' style={{color: "#9696A0", fontSize: "20px", verticalAlign: "middle"}} /> <span style={{paddingLeft: "4px", marginTop: "5px"}}>API URL</span></span>
                        {'noRequestType' in this.props ? null : <span style={{ backgroundColor: color, color: "white", fontSize: "85%", padding: "0 4px 0 4px", fontWeight: "700", marginLeft: "6px"}}>{requestType}</span>}
                    </span>
                </div>
                <p className="wordwrap apiURLText" id="apiURLInput">
                    <Icon name="copy outline"
                          className="fileIDLabelIcon"
                          color="green"
                          style={{cursor: "pointer"}}
                          onClick={this.copyURL}
                    />
                    {this.props.url}
                </p>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    connectedDomains: state.mainState.connectedDomains
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(RequestURLBox)