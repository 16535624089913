import React, {Component} from 'react'
import {connect} from 'react-redux'
import { Button } from 'semantic-ui-react'
import {modifyStatus} from "../Actions/ModifyStatus";
import {toggleModal} from "../Actions/ToggleModal";
import API_Root from "../Constants/API_Root";
import constants from "../Constants/constants";
import {modifyFileInformation} from "../Actions/ModifyFileInformation";
import {modifySheetRead} from "../Actions/ModifySheetRead";
import {toggleToast} from "../Actions/ToggleToast";
import RefreshFile from "./RefreshFile"
import {modifyModalInfo} from "../Actions/ModifyModalInfo";
import {readFiles} from "../Constants/readFile";
import {modifyFilePKProBlock} from "../Actions/ModifyFilePKProBlock";
import getURLParam from "../Constants/getURLParam";
import { push } from 'react-router-redux'
import {modifyEditQueryInfo} from "../Actions/ModifyEditQueryInfo";
import {modifyAPIMenuItem} from "../Actions/ModifyAPIMenuItem";
import API_Backend_Root from "../Constants/API_Backend_Root";

class ReuploadFile extends Component {
    constructor(props) {
        super(props);

        this.reuploadFile = this.reuploadFile.bind(this);
        this.getStatusAndResults = this.getStatusAndResults.bind(this);
        this.fileUploadClick = this.fileUploadClick.bind(this);
    }

    fileUploadClick(e){
        this.fileUpload.click()
    }

    openQuickstartModal = () => {
        this.props.modifyModalInfo({ pkID: this.props.pkID, pk: this.props.pk, fileHash: this.props.fileHash, editQuickstart: true })
        this.props.toggleModal("quickstartUploadModal")
    }

    reuploadFile(e){
        this.props.toggleModal("loadingModalEditFile");

        const files = e.target.files;

        if (files.length > 0){
            const file = files[0];

            const formData = new FormData();

            formData.append("file", file);
            formData.append("email", localStorage.getItem("email"));
            formData.append("token", localStorage.getItem("token"));
            formData.append("pk", this.props.pk);

            this.props.modifyStatus("reading");

            const errorFetch = () => {this.props.toggleToast({show: true, message: "There was an error reading your file 😞. Please try again. Contact our Support if this error persists.", type: "error"}); this.props.modifyStatus("errorSaved"); this.props.toggleModal(null)};

            fetch(API_Root + 'api/reupload-api-file/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                },
                body: formData
            }).then(res => {
                if (res.status === 201){
                    res.json().then(
                        (data) => {
                            const pkArray = [{'id': this.props.pkID, "pk": data.pk}]
                            this.props.modifyStatus("saved");
                            this.getStatusAndResults(pkArray);
                    }).catch(err => errorFetch())

                    // const pkArray = [{'id': this.props.pkID, 'pk': this.props.pk}]
                }
                else{
                    errorFetch()
                }
            }).catch(err => errorFetch());

        }
    }

    getStatusAndResults(filePK){
        let userEmail = localStorage.getItem("email");
        if (userEmail === "martijn@slimpensioneren.nl" || userEmail === "adhaar@apispreadsheets.com"){
            fetch(API_Backend_Root + "send-email-tracking/", {
                method: 'POST',
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
                body: JSON.stringify({
                    subject: 'Enterprise file uploaded',
                    body: 'from: ' + userEmail + ", pk: " + filePK.map(x => x.pk).join(",")
                })
            }).then(res =>
                console.log(res)
            ).catch(err => console.log(err));
        }

        let socketInfo = {
            filePK: filePK,
            sheetRead: this.props.sheetRead,
            email: localStorage.getItem("email"),
            token: localStorage.getItem("token"),
            utcOffset: new Date().getTimezoneOffset(),
            reupload: true,
            originalFilePK: [{'id': this.props.pkID, 'pk': this.props.pk}]
        };

        if (!('fixColumnNames' in this.props)){
            socketInfo['checkColumnNames'] = true
        }

        readFiles(socketInfo, filePK, this.props, "read-api-files", true, false)
    }

    goToEditQuery = () => {
        this.props.modifyModalInfo({queryPK: this.props.fileInformation[this.props.pkID].queryFK, isNew: false, isInRedux: false })
        this.props.modifyEditQueryInfo({queryPK: this.props.fileInformation[this.props.pkID].queryFK, isNew: false, isInRedux: false })
        this.props.navigateTo("/database-edit-api/")
    }

    render() {
        // Hides the Input Button
        const inputFileStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1'
        };

        // Valid extensions for the file upload
        const acceptExtensions = constants.extensions.join(",");

        const inputType = 'inputType' in this.props.fileInformation[this.props.pkID] ? this.props.fileInformation[this.props.pkID]['inputType'] : null;

        if (inputType.includes("file")) {
            return (
            <div>
                <input
                    type="file"
                    name="reinputFileUpload"
                    id="reinputFileUpload"
                    className="form-control-file"
                    onChange={this.reuploadFile}
                    style={inputFileStyle}
                    multiple={false}
                    accept={acceptExtensions}
                    ref={fileUpload => this.fileUpload = fileUpload}
                />
                <label htmlFor="reinputFileUpload" style={{width: '100%'}}>
                    <Button content="Replace File" basic color='green' icon='upload' onClick={this.fileUploadClick} fluid />
                </label>
            </div>
            )
        } else if (inputType === "quickstart"){
            return (
                <div>
                    <Button content="Edit Values" basic color='green' icon='edit alternate outline' onClick={this.openQuickstartModal} fluid />
                </div>
            )
        } else if (inputType === "database"){
            return (
                <div>
                    <Button content="Edit Query" basic color='green' icon='database' onClick={this.goToEditQuery} fluid />
                </div>
            )
        }
        else{
            return(
                <RefreshFile pk={this.props.pk} pkID={this.props.pkID} inputType={inputType}/>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    sheetRead: state.mainState.sheetRead,
    editOptions: state.mainState.editOptions,
    fileInformation: state.mainState.fileInformation,
    filePKProBlock: state.mainState.filePKProBlock
})

const mapActionsToProps = {
    modifyStatus: modifyStatus,
    toggleModal: toggleModal,
    modifyFileInformation: modifyFileInformation,
    modifySheetRead: modifySheetRead,
    toggleToast: toggleToast,
    modifyModalInfo: modifyModalInfo,
    modifyFilePKProBlock: modifyFilePKProBlock,
    navigateTo: push,
    modifyEditQueryInfo: modifyEditQueryInfo,
    modifyAPIMenuItem: modifyAPIMenuItem
}

export default connect(mapStateToProps, mapActionsToProps)(ReuploadFile)