import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../Constants/API_Backend_Root";
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import UtilitiesSampleRequest from "../UtilitiesSampleRequest"
import UpgradeToTeam from "../../FileDnD/UpgradeToTeam"
import {getFullAPIUrl} from "../getFullAPIUrl";
import UtilitiesSampleResponse from "../UtilitiesSampleResponse"
import UtilitiesCodeSnippet from "../UtilitiesCodeSnippet";
import AceEditor from "react-ace";
import 'brace/mode/python';

class APICreateBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            save: false
        }
    }

    getCode = () => {
        if (this.props.api.code.code === ""){
            return "## ENTER YOUR CODE HERE AND MAKE SURE IT RETURNS A STATUS CODE AND BODY LIKE FOLLOWS\ndef api(request_body, headers):\n\treturn 200, {'data': 'my data'}"
        } else {
            return this.props.api.code.code
        }
    }

    changeCode = (updatedCode) => {
        this.props.updateAPI(this.props.idx, {"code": {"code": updatedCode}})

        this.setState({ save: true })
    }

    render() {
        return (
            <div>
                {this.state.save ? <small style={{ color: "red"}}>don't forget to save</small> : null}
                <AceEditor
                    mode="python"
                    theme="monokai"
                    value={this.getCode()}
                    onChange={this.changeCode}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    height={500}
                    width="auto"
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(APICreateBox)