import React, {Component} from 'react'
import {connect} from 'react-redux'
import UtilitiesIndexMenuItem from "./UtilitiesIndexMenuItem"

class UtilitiesIndexMenu extends Component {
    render() {
        return (
            <div className="navbarAuthDiv" style={{minHeight: '100vh', padding: "16px"}}>
                <div style={{marginBottom: "16px"}}>
                    <UtilitiesIndexMenuItem
                        name="ai"
                        icon="keyboard outline"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <UtilitiesIndexMenuItem
                        name="create"
                        icon="upload"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <UtilitiesIndexMenuItem
                        name="download"
                        icon="download"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <UtilitiesIndexMenuItem
                        name="settings"
                        icon="cog"
                    />
                </div>
                <div style={{marginBottom: "16px"}}>
                    <UtilitiesIndexMenuItem
                        name="api"
                        icon="code"
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapActionsToProps = {}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesIndexMenu)