import React, {Component} from 'react'
import DisplayHeader from "./DisplayHeader";
import FilesTabFileSourceNames from "../FilesTabFileSourceNames";
import FilesTabSharedLabel from "../FilesTabSharedLabel"
import FileDescriptionModalBody from "../FileDescription/FileDescriptionModalBody"
import ToggleLazyMode from "./LazyMode/ToggleLazyMode"
import {isFileInLazyMode} from "../../Constants/isFileInLazyMode";
import {getFileInputType} from "../../Constants/getFileInputType";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";

class FileInfoBox extends Component {
    render() {
        const labelStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", color: "#5E645E", marginTop: "16px"}
        const resultStyle = {fontFamily: "Lato", fontWeight: '500', fontSize: "14px", marginTop: "16.5px"}
        const isLazyMode = isFileInLazyMode(this.props.fileInformation);
        let isEnterprise = false

        if ("isEnterprise" in this.props.fileInformation) {
            isEnterprise = this.props.fileInformation.isEnterprise;
        }

        return (
            <div className="filesIDDisplayBox">
                <div style={{marginBottom: "8px"}}>
                    <DisplayHeader header="File Info"/>
                </div>
                <div style={{display: "grid", gridTemplateColumns: "274px auto", columnGap: "24px"}}>
                    <div>
                        <div style={labelStyle}>
                            File Source
                        </div>
                        <div style={labelStyle}>
                            Created On
                        </div>
                        <div style={labelStyle}>
                            Uploaded By
                        </div>
                        <div style={labelStyle}>
                            Description
                        </div>
                    </div>
                    <div>
                        <div style={resultStyle}>
                            <FilesTabFileSourceNames fileInformation={this.props.fileInformation}/>
                        </div>
                        <div style={resultStyle}>
                            {this.props.fileInformation['createdDt']}
                        </div>
                        <div style={resultStyle}>
                            <FilesTabSharedLabel fileInformation={this.props.fileInformation}/>
                        </div>
                        <div style={resultStyle}>
                            <FileDescriptionModalBody pkID={this.props.pkID} pk={this.props.pk} />
                        </div>
                    </div>
                </div>
                {
                    getFileInputType(this.props.fileInformation) === 'googleSheets' ?
                        <div style={resultStyle}>
                            <ToggleLazyMode pk={this.props.pk}
                                            display={isLazyMode ? "Switch to regular mode" : "Switch to non-strict mode"}
                                            pkID={this.props.pkID}
                                            isLazyMode={isLazyMode}
                                            fileInformation={this.props.fileInformation} />
                        </div> : null
                }
                {
                    isEnterprise ?
                        <div style={{fontFamily: "Lato", fontWeight: '500', fontSize: "14px", marginTop: "32px"}}>
                            <span style={{ padding: "12px 16px", backgroundColor: gunaldiStyles.subBlue, color: gunaldiStyles.subText, borderRadius: "24px"}}>ENTERPRISE READY</span>
                        </div> : null
                }
            </div>
        )
    }
}

export default FileInfoBox