import React, {Component} from 'react'
import {connect} from 'react-redux'
import API_Backend_Root from "../../Constants/API_Backend_Root";
import RequestURLBox from "../../SharedComponents/RequestURLBox"
import UtilitiesSampleRequest from "../UtilitiesSampleRequest"
import UpgradeToTeam from "../../FileDnD/UpgradeToTeam"
import {getFullAPIUrl} from "../getFullAPIUrl";
import UtilitiesSampleResponse from "../UtilitiesSampleResponse"
import UtilitiesCodeSnippet from "../UtilitiesCodeSnippet";
import AceEditor from "react-ace";
import 'brace/mode/python';
import { Button } from "semantic-ui-react";
import {gunaldiStyles} from "../../SharedComponents/GunaldiDesignStyles";
import {toggleToast} from "../../Actions/ToggleToast";
import APIAccordion from "./APIAccordion";

class APIIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userApis: [],
            createNewAPI: false
        }
    }

    componentDidMount() {
        this.getAllAPIs()
    }

    getAllAPIs = () => {
        fetch(API_Backend_Root + "api/get-python-apis-for-user/" + localStorage.getItem("email") + "/")
            .then(res => res.json())
            .then(data => {
                this.setState({
                    userApis: data.userApis
                })
            })
    }

    getDivStyle = (isTeam) => {
        let style = {marginTop: "16px"}

        if (!isTeam){
            style['pointerEvents'] = "none"
            style['opacity'] = "0.5"
            // style['cursor'] = "not-allowed"
        }

        return style
    }

    saveNewAPI = () => {
        this.setState({ createNewAPI: true })

        const isError = () => {
            this.setState({ createNewAPI: false },
                () =>
                    this.props.toggleToast({show: true, message: "There was an error creating your API! Please try again", type: "error"}))
        }

        fetch(API_Backend_Root + "api/save-new-python-api/", {
            method: 'POST',
            body: JSON.stringify({
                email: localStorage.getItem("email")
            })
        }).then((res) => res.json())
            .then((data) => {
                const currApis = JSON.parse(JSON.stringify(this.state)).userApis
                currApis.unshift(data)

                this.setState({
                    userApis: currApis,
                    createNewAPI: false
                })
        }).catch(err => isError()).catch(err => isError())
    }

    updateAPI = (apiIndex, updates) => {
        let currApis = JSON.parse(JSON.stringify(this.state)).userApis
        let currApi = currApis[apiIndex]

        for (let key in updates) {
            currApi[key] = updates[key]
        }

        currApis[apiIndex] = currApi

        this.setState({
            userApis: currApis
        })
    }

    render() {
        const isTeam = this.props.tier === "team" || this.props.tier === "business" || this.props.tier === "enterprise";

        const url = getFullAPIUrl(this.props.baseURL, "api")
        console.log(this.state)

        const rulesStyle = {}
        return (
            <div>
                <div style={isTeam ? null : {"cursor": "not-allowed"}}>
                    <div style={this.getDivStyle(isTeam)}>
                        <div style={{marginBottom: "24px"}}>
                            <div>
                                <Button content="Create a new generic API"
                                        icon="plus"
                                        style={gunaldiStyles.btnGreen}
                                        loading={this.state.createNewAPI}
                                        onClick={this.saveNewAPI}/>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: "24px"}}>
                            <div className="col-sm-9">
                                {
                                    this.state.userApis.length > 0 ?
                                        this.state.userApis.map((apis, idx) =>
                                            <div style={{marginBottom: "12px"}} key={apis.hash}>
                                                <APIAccordion
                                                    api={apis} idx={idx}
                                                    updateAPI={this.updateAPI}
                                                />
                                            </div>) : null
                                }
                            </div>
                            <div className="col-sm-3">
                                <b>Rules for creating a custom API</b>
                                <div style={rulesStyle}>1. APIs can be called as a GET or POST</div>
                                <div style={rulesStyle}>2. DO NOT CHANGE the specified function name and parameters</div>
                                <div style={rulesStyle}>3. You can use the headers and request_body parameters to access your
                                    headers and request_body. Note, headers will be capitalized automatically
                                </div>
                                <div style={rulesStyle}>4. You must return a tuple of status_code and response body from
                                    your code as shown
                                </div>
                                <div style={rulesStyle}>5. The response body must be returned even if it is None</div>
                            </div>
                        </div>
                        {/*<div style={{ marginBottom: "24px"}}>*/}
                        {/*    <RequestURLBox url={url} />*/}
                        {/*</div>*/}
                        </div>
                    </div>
                </div>
            )
        }
}

const mapStateToProps = (state) => ({
    tier: state.mainState.tier
})

const mapActionsToProps = {
    toggleToast: toggleToast
}

export default connect(mapStateToProps, mapActionsToProps)(APIIndex)