import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getQueryParamValue} from "../Constants/getQueryParamValue";
import {modifyUtilitiesMenuItem} from "../Actions/ModifyUtilitiesMenuItem";
import UtilitiesIndexMenu from "./UtilitiesIndexMenu"
import BannerMessages from "../App/API/BannerMessages";
import UtilitiesHeader from "./UtilitiesHeader";
import AIIndex from "./AI/AIIndex"
import {toggleToast} from "../Actions/ToggleToast";
import API_Root from "../Constants/API_Root";
import Loader from 'react-loader-spinner'
import SettingsIndex from "./Settings/SettingsIndex";
import CreateIndex from "./Create/CreateIndex"
import DownloadIndex from "./Download/DownloadIndex"
import {modifyUtilitiesInfo} from "../Actions/ModifyUtiltiesInfo";
import {getConnectedDomainLink} from "../Constants/getConnectedDomainLink";
import API_Backend_Root from "../Constants/API_Backend_Root";
import APIIndex from "./API/APIIndex";

class UtilitiesIndex extends Component {
    constructor(props){
        super(props);

        this.state = {
            fetching: true,
        }
    }
    componentDidMount(){
        const error = () => {
            this.setState({
                fetching: false
            }, () => this.props.toggleToast({ show: true, type: "error", message: "There was an error getting your utilities settings. Please refresh and try again. Contact support if error persists"}))
        }

        fetch(API_Root + "api/get-api-utilities-info/", {
            method: "POST",
            body: JSON.stringify({
                email: localStorage.getItem("email"),
                token: localStorage.getItem("token")
            }),
            headers: {"Content-Type": "text/plain"},
        }).then(res => {
            if (res.status === 200){
                res.json().then(data => {
                    this.setState({ fetching: false }, () => this.props.modifyUtilitiesInfo(data))
                }).catch(err => error())
            } else {
                error()
            }
        }).catch(err => error())

        const menuItem = getQueryParamValue("menuItem", window.location.href)

        if (menuItem !== ""){
            this.props.modifyUtilitiesMenuItem(menuItem)
        }
    }

    changeState = (newState) => {
        const currInfo = JSON.parse(JSON.stringify(this.props.utilitiesInfo));

        for (let key in newState){
            currInfo[key] = newState[key]
        }

        this.props.modifyUtilitiesInfo(currInfo)
    }

    getDisplay = () => {
        if (this.state.fetching){
            return <div style={{ padding: "24px 48px"}}>
                <Loader
                    type="TailSpin"
                    color="black"
                    height="50"
                    width="50"
                />
                <h4>Setting up your utilities page...</h4>
            </div>
        }

        const headers = {
            'ai': "Query your data using natural language with an API. Powered by AI",
            'create': "API to create spreadsheets in your API Spreadsheets Account",
            'download': 'API to download data in a spreadsheet from your web app',
            'settings': 'Settings',
            'api': 'Write Python code to create an API'
        }

        const icons = {
            'ai': "keyboard outline",
            'create': "upload",
            'download': 'download',
            'settings': 'cog',
            'api': 'code'
        }

        const itemBody = {
            'ai': <AIIndex baseURL={this.getBaseUrl()}/>,
            'create': <CreateIndex baseURL={this.getBaseUrl()}/>,
            'download': <DownloadIndex baseURL={this.getBaseUrl()}/>,
            'settings': <SettingsIndex settings={this.props.utilitiesInfo} changeState={this.changeState}/>,
            'api': <APIIndex baseURL={this.getBaseUrl()}/>
        }

        const menuItem = this.props.utilitiesMenuItem;

        return <div style={{ padding: "24px 48px"}}>
            <div style={{ marginBottom: "24px" }}>
                <UtilitiesHeader icon={icons[menuItem]} header={headers[menuItem]}/>
            </div>
            <div>
                {itemBody[menuItem]}
            </div>
        </div>

    }

    getBaseUrl = () => {
        let baseUrl;

        const connectedDomainLink = getConnectedDomainLink(this.props.connectedDomains)
        if (connectedDomainLink !== null){
            baseUrl  = connectedDomainLink + "/"
        } else {
            baseUrl = API_Backend_Root
        }

        return baseUrl
    }

    render() {
        return (
            <div style={{minHeight: '100vh'}}>
                <div style={{display: "grid", gridTemplateColumns: "100px 1fr"}}>
                    <div>
                        <UtilitiesIndexMenu />
                    </div>
                    <div>
                        <BannerMessages/>
                        <div>
                            {this.getDisplay()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    utilitiesMenuItem: state.mainState.utilitiesMenuItem,
    utilitiesInfo: state.mainState.utilitiesInfo,
    connectedDomains: state.mainState.connectedDomains
})

const mapActionsToProps = {
    modifyUtilitiesMenuItem: modifyUtilitiesMenuItem,
    toggleToast: toggleToast,
    modifyUtilitiesInfo: modifyUtilitiesInfo
}

export default connect(mapStateToProps, mapActionsToProps)(UtilitiesIndex)